// @flow strict
import React from "react";
import { getContactHref } from "../../../utils";
import styles from "./Author.module.scss";
import { useSiteMetadata } from "../../../hooks";
import { withPrefix, Link } from "gatsby";

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles["author"]}>
      <Link to="/">
        <img
          src={withPrefix(author.photo)}
          className={styles["author__photo"]}
          width="75"
          height="75"
          alt={author.name}
        />
      </Link>

      <p className={styles["author__bio"]}>
        {author.bio}
        <a
          className={styles["author__bio-twitter"]}
          href={getContactHref("github", author.contacts.github)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong>{author.name}</strong> on GitHub
        </a>
      </p>
    </div>
  );
};

export default Author;
